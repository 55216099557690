import gsap, { SplitText } from "@/lib/gsap";
import React, { useEffect, useRef } from "react";
import ExternalLink from "./ExternalLink";
import Illustration from "./Illustration";
import InternalLink from "./InternalLink";
import {
  ButtonsContainer,
  SingleLaunchContainer,
  Content,
  Excerpt,
  Heading,
  Label,
} from "./styles";
import _SplitText from "gsap/SplitText";
import { debounce } from "lodash";

export type SingleLaunchProps = {
  _key?: string;
  variant?: string;
  index: number;
  excerpt?: any;
  heading?: any;
  label?: any;
  image?: any;
  buttons?: any[];
};

const SingleLaunch: React.FC<SingleLaunchProps> = (props) => {
  const { image, excerpt, heading, label, buttons, variant, index } = props;
  const imagePosition =
    variant === "double" ? "bottom" : image?.position || "right";

  const containerRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLParagraphElement>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const excerptRef = useRef<HTMLParagraphElement>(null);
  const buttonsContainerRef = useRef<HTMLDivElement>(null);

  const labelTextRef = useRef<_SplitText | null>(null);
  const headingTextRef = useRef<_SplitText | null>(null);
  const excerptTextRef = useRef<_SplitText | null>(null);

  useEffect(() => {
    // Create closure around mutable refs
    const container = containerRef.current;
    const imageWrapper = imageWrapperRef.current;
    const label = labelRef.current;
    const heading = headingRef.current;
    const excerpt = excerptRef.current;
    const buttonsContainer = buttonsContainerRef.current;
    if (!buttonsContainer) return;

    labelTextRef.current = new SplitText(label, { type: "lines", linesClass: "lines" });
    headingTextRef.current = new SplitText(heading, { type: "lines", linesClass: "lines" });
    excerptTextRef.current = new SplitText(excerpt, { type: "lines", linesClass: "lines" });
    const labelText = labelTextRef.current;
    const headingText = headingTextRef.current;
    const excerptText = excerptTextRef.current;
    const revertText = debounce(() => {
      if (headingText && labelText && excerptText) {
        labelText?.revert()
        headingText?.revert()
        excerptText?.revert()
      }
    }, 30);

    window.addEventListener("resize", revertText);
    gsap.set([label, heading, excerpt], { opacity: 1.0 });

    const timeline = gsap
      .timeline({
        defaults: {
          duration: 0.3,
        },
        scrollTrigger: {
          trigger: container,
          toggleActions: "play pause resume reverse",
          // scrub: 0.5,
          start: "top 75%",
        },
      })
      .to(imageWrapper, {
        opacity: 1.0,
        translateY: 0,
      })
      .to(
        [
          labelText.lines,
          headingText.lines,
          excerptText.lines,
          buttonsContainer.children,
        ],
        {
          opacity: 1.0,
          translateY: 0,
          stagger: { amount: 0.3 },
        },
        "<25%"
      );

    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, []);

  return (
    <SingleLaunchContainer
      ref={containerRef}
      $imagePosition={imagePosition}
      $variant={variant}
      $index={index}
    >
      <Illustration ref={imageWrapperRef} image={image} variant={variant} />
      <Content $variant={variant}>
        {label && <Label ref={labelRef}>{label}</Label>}
        <Heading ref={headingRef}>{heading}</Heading>
        {excerpt && (
          <Excerpt
            ref={excerptRef}
            dangerouslySetInnerHTML={{
              __html: excerpt.replace(/\n/g, "<br />"),
            }}
            $variant={variant}
          ></Excerpt>
        )}
        <ButtonsContainer ref={buttonsContainerRef} $variant={variant}>
          {buttons?.map((button: any) => {
            if (!button?._key) return null;
            switch (button._type) {
              case "internalLink":
                return <InternalLink key={button._key} {...button} />;
              case "externalLink":
                return <ExternalLink key={button._key} {...button} />;
              default:
                return null;
            }
          })}
        </ButtonsContainer>
      </Content>
    </SingleLaunchContainer>
  );
};

export default SingleLaunch;
