import SingleLaunch, { SingleLaunchProps } from "./SingleLaunch/SingleLaunch";
import { Container } from "./styles";

type Props = {
  variant: string;
  single: SingleLaunchProps;
  double: SingleLaunchProps[];
};

const Launch: React.FC<Props> = (props) => {
  const { variant = "single" } = props;

  return (
    <Container $variant={variant}>
      {variant === "single" && props[variant] && (
        <SingleLaunch {...props[variant]} />
      )}
      {variant === "double" &&
        props[variant] &&
        props[variant].map((singleLaunch) => (
          <SingleLaunch
            key={singleLaunch._key}
            variant={variant}
            {...singleLaunch}
          />
        ))}
    </Container>
  );
};

export default Launch;
