import styled from "styled-components";
import { ImageWrapper } from "@/lib/styles";

export const Container = styled(ImageWrapper)<{ $aspectRatio: number, $variant?: string }>`
  background-color: ${({ theme }) => theme.colors.white};
  width: 50%;
  align-self: ${({ $variant }) => $variant === "double" ? "center" : "flex-start"};
  padding-bottom: calc(${({ $aspectRatio }) => 1 / $aspectRatio} * 50%);
  opacity: 0;
  transform: translateY(100px);
  align-self: self-end;
  margin: 0 auto;
`;
