import CallToAction from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { forwardRef } from "react";
import styled from "styled-components";
import { ImageWrapper as _ImageWrapper } from "@/lib/styles/theme";
import { H3, P, Small } from "@/lib/styles";

export const SingleLaunchContainer = styled.div<{
  $imagePosition?: string;
  $variant?: string;
  $index: number;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  border-left-width: ${({ $index }) => ($index === 1 ? "0" : "1px")};
  
  
  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: ${({ $imagePosition }) => {
      let direction = $imagePosition === "right" ? "row-reverse" : "row";
      direction = $imagePosition === "bottom" ? "column-reverse" : direction;
      return direction;
    }};
    flex-wrap: nowrap;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

`;

export const Content = styled.div<{ $variant?: string }>`
  ${({ theme }) => theme.mediaQueries.laptop} {
    width: ${({ $variant }) =>
      $variant === "double" ? "calc(100% - 3rem)" : "calc(50% - 1.5rem)"};
    margin-left: 1.5rem;
    margin-right: ${({ $variant }) => ($variant === "double" ? "1.5rem" : "0")};
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: ${({ $variant }) =>
      $variant === "double" ? "center" : "baseline"};
    align-self: flex-end;
    flex-direction: column;
  }
`;

export const Excerpt = styled(P)<{ $variant?: string }>`
  opacity: 0;
  padding: 0rem 1.5rem;
  margin-bottom: 2rem;
  text-align: ${({ $variant }) => ($variant === "double" ? "center" : "left")};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    justify-content: start;
    padding: 0.5rem 0;
  }
`;

export const Heading = styled(H3)`
  margin-bottom: 1.5rem;
  opacity: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;

export const Label = styled(Small)<{ $variant?: string }>`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 0.875rem;
  line-height: 1.25;
  opacity: 0;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  margin-top: ${({ $variant }) => ($variant === "double" ? "0" : "1.5rem")};
  display: flex;
  justify-content: center;

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const _Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string; target?: string }
>((props, ref) => (
  <CallToAction
    ref={ref}
    as="a"
    variant="primaryUnboxed"
    icon={<SVGArrowRight />}
    {...props}
  />
));

_Button.displayName = "_Button";

export const Button = styled(_Button)`
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 2.5rem;
  padding: 0; // This covers a design exception
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 1.5rem; // This covers a design exception
  }
`;

export const ButtonsContainer = styled.div<{ $variant?: string }>`
  align-self: ${({ $variant }) => ($variant === "double" ? "center" : "start")};
`;
