import { imageLoader } from "@/lib/sanity/client";
import NextImage from "next/image";
import React, { forwardRef, useState } from "react";
import { Container } from "./styles";

type Props = {
  image?: any;
  variant?: string;
};

const Illustration = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { image, variant } = props;
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <Container
      $hasLoaded={loaded}
      $aspectRatio={image?.asset?.aspectRatio || 1}
      $variant={variant}
      ref={ref}
    >
      {image?.asset?.url && (
        <NextImage
          src={image.asset.url}
          layout="fill"
          objectFit="cover"
          alt={image.alt || ""}
          loader={imageLoader}
          onLoadingComplete={() => setLoaded(true)}
          sizes="50vw"
          priority
        />
      )}
    </Container>
  );
});

Illustration.displayName = "Illustration";

export default Illustration;
