import styled from "styled-components";
import { H2, P } from "@/lib/styles";

export const Container = styled.div<{ $variant?: string }>`
  display: ${({ $variant }) => $variant === "single" ? "block" : "flex"};
  flex-direction: ${({ $variant }) => $variant === "single" ? "row" : "column"};

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
  }
`;
